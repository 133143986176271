@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --nav-bar: #F03A47;
  --bg-color: #515052;
  --primary-dark: #333333;
  --secondary-dark: #414141;
  --dark-hover: #313131;
  --primary-light: #F7A399;
  --secondary-light: #FBC3BC;
  --light-hover: #FFE3E0;
  --primary-discord: #5865F2;
  --secondary-discord: #7289DA;
  --discord-hover: #4062BB;
  --primary-itch: #de4660;
  --secondary-itch: #e46a7f;
  --itch-hover: #c73f56;
}


@layer base {
  html {
    @apply scroll-smooth
  }

  body {
    @apply font-[Poppins]
    bg-[var(--bg-color)]
  }

  li {
    @apply p-4 text-sm
  }

  button {
    @apply px-4 py-2 bg-[var(--primary-dark)] text-white
  }

  h2 {
    @apply text-xl font-bold
  }
}